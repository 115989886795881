<template>
 <ValidationProvider
    tag="div"
    v-bind="$attrs"
    :rules="_rules"
    v-slot="{ errors, failed }"
    :name="_name"
    ref="validation_observe"
  >
  <div class="form-check-inline">
    <label :for="label">{{ label }}</label>
    <span v-if="_required" class="text-danger">*</span>
    <div class="">
      <select :name="name" v-model="_value">
          <option v-for="(item, index) in options" :key="index" :value="item.id">{{item.name}}</option>
      </select>
      <div v-if="failed" class="text-danger">{{ errors[0] }}</div>
    </div>
  </div>
  </ValidationProvider>
</template>

<script>
const REQUIRED = "required";
export default {
  props: {
    label: { type: String, default: "" },
    name: { type: String, require: true },
    required: { type: Boolean, default: false },
    value: { require: true, default: "" },
    options: { type: Array, default: null },
    customClass: { type: String, default: "" },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
    _required() {
      return this.required ? this.required : this._rules.includes(REQUIRED);
    },
    _name() {
      return this.label ?? this.name;
    },
    _rules() {
      let rules = this.rules ? this.rules.split("|") : [];
      if (this.required && !rules.includes(REQUIRED)) {
        rules.push(REQUIRED);
      }
      return rules.join("|");
    },
  },
};
</script>

<style>
</style>