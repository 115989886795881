import Vue from "vue";

const getters = {
  
}

const actions = {
    async uploadImage({ commit }, {file, path="file", cb}) {
        const formData = new FormData()
        formData.append("variable", "file");
        formData.append("path", path);
        formData.append("file", file);
        let url = "/api/v1/image/upload";
        let response = await Vue.prototype.$request.
                            _request({
                                url, 
                                method: "post", 
                                data: formData, 
                                headers: {
                                    "Content-Type": "multipart/form-data"
                                }
                            });
        cb(response);
    },
    async uploadFile({ commit }, {file, path="lessons", cb}) {
        const formData = new FormData()
        formData.append("variable", "file");
        formData.append("path", path);
        formData.append("file", file);
        let url = "/api/v1/file/upload";
        await Vue.prototype.$request.
                            _request({
                                url, 
                                method: "post", 
                                data: formData, 
                                headers: {
                                    "Content-Type": "multipart/form-data"
                                }
                            })
                            .then(response => cb(response))
                            .catch(error => cb(error.response));
    }
}

const mutations = {
   
}

const state = {
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
