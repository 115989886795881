<template>
  <div class="popup-modal" role="dialog" v-if="isVisible">
    <div class="wrapper">
      <div class="modal-dialog">
        <div :class="`modal-content ${!title ? 'no-title' : ''}`">
          <div class="modal-header">
            <div></div>
            <h5 class="modal-title">{{ title }}</h5>
            <div></div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="_cancel"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div 
              :class="`${isJustifyBetween ? 'modal-footer d-flex justify-content-between' : 'modal-footer'}`" 
              v-if="!hideAllButton"
              :style="`${hideCancelButton ? 'justify-content: center;' : ''}`"
            >
            <button
              type="button"
              :class="`btn btn-secondary ${hideCancelButton ? 'd-none':''}`"
              data-dismiss="modal"
              @click="_cancel"
            >
              {{ cancelButton ? cancelButton : $t("common.cancel") }}
            </button>
            <button type="button" class="btn btn-primary" @click="_confirm">
              {{ confirmButton ? confirmButton : $t("common.okay") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "isVisible",
    "title",
    "cancelButton",
    "confirmButton",
    "isJustifyBetween",
    "hideCancelButton",
    "hideAllButton",
    "overrideClass"
  ],
  name: "Popup",
  data() {
    return {};
  },
  methods: {
    _confirm() {
      this.$emit("confirm");
    },
    _cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
