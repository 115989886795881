<template>
  <div >
    <footer class="text-muted">
      <div class="main">
        <div class="row">
            <div class="col-12 logo text-center">
                <router-link to="/">
                  <img src="/img/header-logo.png"/>
                </router-link>
            </div>
            <div class="col-12 menu-container">
              <ul class="menu-list">
                <li class="main-item">
                  <router-link 
                      :to="{ name: 'lesson.category.list', params: {slug: ['all']}}">
                      すべてのカテゴリ
                  </router-link>
                </li>
              </ul>
              <ul class="menu-list" v-for="(item, index) in categories.data" :key="index">
                  <li class="main-item">
                    <router-link 
                      :to="{ name: 'lesson.category.list', params: {slug: item.slug_location_arr}}">
                      {{item.name}}
                    </router-link>
                  </li>
                  <li class="sub-item" v-for="(i, key) in item.children" :key="key">
                    <router-link 
                      :to="{ name: 'lesson.category.list', params: {slug: i.slug_location_arr}}">
                      {{ i.name }}
                    </router-link>
                  </li>
              </ul>
            </div>
        </div>
      </div>
    </footer>
    <div class="footer">
        <div class="row">
            <div class="col-12 text-center">
                <ul class="menu-list-static">
                  <li>
                    <router-link to="/terms"> 
                      利用規約
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/privacy-policy"> 
                      個人情報の取り扱いについて
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/sctl"> 
                      特商法表記について
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'inquiry'}"  class="last-child"> 
                      お問い合わせ
                    </router-link>
                  </li>
                </ul>
            </div>
            <div class="col-12 text-center">
              ©2024 IID, Inc.
            </div>
        </div>
    </div>
</div>
</template>
<script>
  import { mapGetters } from "vuex";
  
  export default {
      name: "FooterPC",
      computed: {
          ...mapGetters({
              categories: "lessonCategory/getCategoryMenu",
          })
      },
  }
  </script>