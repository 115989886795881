import Vue from 'vue';

window.MMSCallback = (() => {
    
    let onLogged = function () {
        let isLogined = Vue.$cookies.get("logined");
        let token = Vue.$cookies.get("X-MMS-Access-Token");
        if (!Number(isLogined) && token) {
        Vue.$cookies.set("logined", 1);
        setTimeout(() => {
            window.location.reload();
        }, 1300);
        }
    };

    let onLogout = function () {
        let isLogined = Vue.$cookies.get("logined");
        if (Number(isLogined)) {
            Vue.$cookies.remove("/");
            Vue.$cookies.set("logined", 0);
            setTimeout(() => {
                window.location.reload();
            }, 100);
        }
    };

    let setPaymentListener = function (callbackPayment) {
        this.callbackPayment = callbackPayment;
    };

    let onPaymentSuccess = function (data) {
        if (this.callbackPayment && typeof this.callbackPayment === 'function') {
            this.callbackPayment(data);
        }
    };

    let onPaymentFail = () => {
      
    };

    let setProfileChangedListener = function (cbProfileChanged) {
        this.cbProfileChanged = cbProfileChanged;
    };

    let onProfileChange = function () {
        if (this.cbProfileChanged && typeof this.cbProfileChanged === 'function') {
            this.cbProfileChanged(true);
        }
    }

    let setVerifiedEmailListener = function (cbVerifiedEmail) {
        this.cbVerifiedEmail = cbVerifiedEmail;
    };

    let onVerifyEmailSuccess = function () {
        if (this.cbVerifiedEmail && typeof this.cbVerifiedEmail === 'function') {
            this.cbVerifiedEmail(1);
        }
    }

    return {
        onLogged,
        onLogout,
        onProfileChange,
        onPaymentFail,
        onPaymentSuccess,
        setPaymentListener,
        setProfileChangedListener,
        setVerifiedEmailListener,
        onVerifyEmailSuccess
    };
})();

window.MMS.register();