const routes = [
	{
		path: '/teacher/:teacherId/profile',
		name: 'teacher-profile',
		component: () => import('@/modules/pc/page/teacher/profile.vue'),
	},
	{
		path: '/teacher/:lessonId/inquiry',
		name: 'inquiry-teacher',
		component: () => import('@/modules/pc/page/inquiry-to-teacher/index.vue'),
	},
	{
		path: '/student/:teacherId/profile',
		name: 'student-profile',
		component: () => import('@/modules/pc/page/student/profile.vue'),
	},
];

export default routes;