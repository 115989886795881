<template>
    <div>
        <div class="navmenu navmenu-default navmenu-fixed-right offcanvas-sm">
            <div class="overlay">
                <div>
                    <button type="button" class="navbar-toggle btn btn-x" data-toggle="offcanvas" data-target=".navmenu">
                        &#x2715;
                    </button>
                </div>
                <div class="category">
                    <category/>
                </div>
            </div>
        </div>
   </div>
</template>
<script>
import category from '../category/category.vue';
export default {
    name: "NavRight",
    components: { 
        category
    },
}
</script>

<style scoped>
    
    .navmenu {
      padding-top: 50px;
    }
    
    .navbar {
      display: block;
      text-align: center;
    }
    .navbar-brand {
      display: inline-block;
      float: none;
    }
    .navbar-toggle {
      position: absolute;
      float: left;
      margin-left: 15px;
    }
    
    .container {
      max-width: 100%;
    }
    
    @media (min-width: 1px) {
      .navbar-toggle {
        display: block !important;
      }
    }
    
    @media (min-width: 992px) {
      body {
        padding: 0 0 0 300px;
      }
      .navmenu {
        padding-top: 0;
      }
      .navbar {
        display: none !important; /* IE8 fix */
      }
    }
    </style>