//websocket
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

export default new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_WEBSOCKET_KEY,
  wsHost: process.env.VUE_APP_WEBSOCKET_SERVER,
  wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
  forceTLS: true,
  disableStats: true
})
