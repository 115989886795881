<template>
    <div></div>
</template>
<script>
export default {
    metaInfo() {
        return {
            title: this.title,
        }
    },
    props: {
        title: {
            type: String,
            default: "ReseMom相談online"
        },
    },
}
</script>