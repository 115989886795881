import Vue from "vue";

const types = {
    LIST_INQUIRIES: "LIST_INQUIRIES",
    DETAIL_INQUIRY: "DETAIL_INQUIRY"
}

const getters = {
    "list": () => state.list,
    "detail": () => state.detail
}

const actions = {
    async getInquiries({ commit }, params) {
        let url = "/api/v1/inquiry";
        let response = await Vue.prototype.$request
            .get({ url, params });
        commit(types.LIST_INQUIRIES, response);
    },
    async getInquiry({ commit }, {id, params}) {
        let url = `/api/v1/inquiry/${id}`;
        let response = await Vue.prototype.$request
                .get({ url });
        commit(types.DETAIL_INQUIRY, response);   
    },
    async replyInquiry({ commit }, {id, data, cb}) {
        let url = `/api/v1/inquiry/${id}`;
        await Vue.prototype.$request
                .post({ url, data })
                .then(response => cb(response))
                .catch(error => cb(error.response));
    },
    async getReportInquiry({ commit }, {id, cb}){
        let url = `/api/v1/inquiry-report/${id}`;
        await Vue.prototype.$request
                .get({ url })
                .then(response => cb(response))
                .catch(error =>  cb(error.response)); 
    },
    async reportInquiry({ commit }, {id, data, cb}){
        let url = `/api/v1/inquiry-report/${id}`;
        await Vue.prototype.$request
                .post({ url, data })
                .then(response => cb(response))
                .catch(error =>  cb(error.response)); 
    },
    async getInquiryReply({ commit }, {id, params, cb}){
        let url = `/api/v1/inquiry-reply/${id}`;
        await Vue.prototype.$request
                .get({ url, params })
                .then(response => cb(response))
                .catch(error =>  cb(error.response)); 
    },
}

const mutations = {
    [types.LIST_INQUIRIES](state, value) {
        state.list = {
            data: value.data,
            pagination: value.meta.pagination
        }
    },
    [types.DETAIL_INQUIRY](state, value) {
        state.detail = value.data;
    },
}

const state = {
    list: {
        data: [],
        pagination: {
            page: 1,
            last: 1
        }
    },
    detail: {
        teacher: {},
        replier: {},
        lesson: {},
        groupProfile: {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
