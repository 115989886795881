import Vue from "vue";

const types = {
    MYPAGE_INFORMATION: "MYPAGE_INFORMATION",
    MYPAGE_ONGOING_LESSON: "MYPAGE_ONGOING_LESSON",
    MYPAGE_FINISHED_LESSON: "MYPAGE_FINISHED_LESSON",
    MYPAGE_CANCELLED_LESSON: "MYPAGE_CANCELLED_LESSON",
    MYPAGE_FAVORITE_LESSON: "MYPAGE_FAVORITE_LESSON",
    MYPAGE_FAVORITE_TEACHER: "MYPAGE_FAVORITE_TEACHER",
    REVIEW_LESSON: "REVIEW_LESSON",
    MYPAGE_PROFILE: "MYPAGE_PROFILE",
    MYPAGE_AVAILABLE_LESSON: "MYPAGE_AVAILABLE_LESSON",
    MYPAGE_REVIEW: "MYPAGE_REVIEW",
    MYPAGE_SCHEDULE_LESSON: "MYPAGE_SCHEDULE_LESSON"
}

const getters = {
    getInformation: (state) => state.mypageInformation,
    getMypageProfile: (state) => state.mypageProfile,
    getMypageReview: (state) => state.mypageReview,
    getMypageAvailableLesson: (state) => state.mypageAvailableLesson,
    getMyPageOngoingLesson: (state) => state.mypageOngoingLesson,
    getMyPageFinishedLesson: (state) => state.mypageFinishedLesson,
    getMyPageCancelledLesson: (state) => state.getMyPageCancelledLesson,
    getFavoriteListTeacher: (state) => state.favoriteListTeacher,
    getFavoriteListLesson: (state) => state.favoriteListLesson,
    getReviewLessonOnStudent: (state) => state.reviewLesson,
    getMypageScheduleLesson: (state) => state.mypageScheduleLesson
}

const actions = {
    async fetchMypageInformation({ commit }, params) {
        let url = "/api/v1/mypage/information";
        const response = await Vue.prototype.$request.get({ url, params });
        commit(types.MYPAGE_INFORMATION, response.data);
    },

    async fetchMypageProfile({ commit }, params) {
        let url = "/api/v1/mypage/profile";
        const response = await Vue.prototype.$request.get({ url, params });
        commit(types.MYPAGE_PROFILE, response.data);
    },

    async fetchMypageReview({ commit }, params) {
        let url = "/api/v1/mypage/profile/review";
        const response = await Vue.prototype.$request.get({ url, params });
        commit(types.MYPAGE_REVIEW, response);
    },

    async fetchMypageAvailableLesson({ commit }, params) {
        let url = "/api/v1/mypage/profile/available-lesson";
        const response = await Vue.prototype.$request.get({ url, params });
        commit(types.MYPAGE_AVAILABLE_LESSON, response);
    },

    async fetchMypageScheduleLesson({ commit }, params) {
        let url = "/api/v1/mypage/profile/schedule-lesson";
        const response = await Vue.prototype.$request.get({ url, params });
        commit(types.MYPAGE_SCHEDULE_LESSON, response.data);
    },

    async fetchMypageLessonList({ commit }, params) {
        let url = "/api/v1/mypage/list-lesson";
        const response = await Vue.prototype.$request.get({ url, params });
        if (params.type == 'finished') {
            commit(types.MYPAGE_FINISHED_LESSON, response);
        }else if(params.type == 'cancelled'){
            commit(types.MYPAGE_CANCELLED_LESSON, response);
        }else{
            commit(types.MYPAGE_ONGOING_LESSON, response);
        }
    },
    async updateCommentLesson({ commit }, { params, cb }) {
        let url = "/api/v1/mypage/update-comment-lesson";
        return await Vue.prototype.$request.get({ url, params });
    },
    async fetchMyPageFavoriteList({ commit }, params) {
        let url = "api/v1/mypage/favorite";
        const response = await Vue.prototype.$request.get({ url, params });
        if(params.type == 'teacher'){
            commit(types.MYPAGE_FAVORITE_TEACHER, response);
        }else{
            commit(types.MYPAGE_FAVORITE_LESSON, response);
        }
    },
    async fetchCommentLessonOnStudent({ commit }, params) {
        let id = params.id
        let url = `/api/v1/mypage/student-profile/comment/${id}`;
        const response = await Vue.prototype.$request.get({ url, params });
        commit(types.REVIEW_LESSON, response);
    },
    async cancelCaptureSale({ commit }, { data, cb }) {
        let url = `/api/v1/mypage/cancel-purchase`;
        await Vue.prototype.$request.post({ url, data })
            .then(response => cb(response))
            .catch(error => cb(error.response));
    },
}

const mutations = {
    [types.MYPAGE_INFORMATION](state, value) {
        state.mypageInformation = value
    },
    [types.MYPAGE_ONGOING_LESSON](state, value) {
        state.mypageOngoingLesson = value
    },
    [types.MYPAGE_FINISHED_LESSON](state, value) {
        state.mypageFinishedLesson = value
    },
    [types.MYPAGE_CANCELLED_LESSON](state, value) {
        state.getMyPageCancelledLesson = value
    },
    [types.MYPAGE_FAVORITE](state, value) {
        state.mypageFavorite = value
    },
    [types.MYPAGE_FAVORITE_LESSON](state, value) {
        state.favoriteListLesson = value
    },
    [types.MYPAGE_FAVORITE_TEACHER](state, value) {
        state.favoriteListTeacher = value
    },
    [types.REVIEW_LESSON](state, value) {
        state.reviewLesson = value
    },
    [types.MYPAGE_PROFILE](state, value) {
        state.mypageProfile = value
    },
    [types.MYPAGE_AVAILABLE_LESSON](state, value) {
        state.mypageAvailableLesson = value
    },
    [types.MYPAGE_REVIEW](state, value) {
        state.mypageReview = value
    },
    [types.MYPAGE_SCHEDULE_LESSON](state, value) {
        state.mypageScheduleLesson = value
    }
}

const state = {
    mypageInformation: {
        data: [],
        meta: {}
    },
    mypageOngoingLesson: {
        data: [],
        meta: {}
    },
    mypageFinishedLesson: {
        data: [],
        meta: {}
    },
    getMyPageCancelledLesson: {
        data: [],
        meta: {}
    },
    favoriteListLesson: {
        data: [],
        meta: {}
    },
    favoriteListTeacher: {
        data: [],
        meta: {}
    },
    reviewLesson: {
        data: [],
        meta: {}
    },
    mypageProfile: {
        data: [],
    },
    mypageAvailableLesson: {
        data: [],
        meta: {}
    },
    mypageReview: {
        data: [],
        meta: {}
    },
    mypageScheduleLesson: []
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
