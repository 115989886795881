<template>
  <ValidationProvider
      tag="div"
      v-bind="$attrs"
      :rules="_rules"
      v-slot="{ errors, failed }"
      :name="_name"
      ref="validation_observe"
    >
    <div>
        <label>{{label}}</label>
        <span v-if="required" class="text-danger">*</span>
        <br/>
        <b v-if="subLabel">{{ subLabel }}</b>
        <input style="display: none" ref="fileInput" type="file" @change="onFileChange" accept="image/*"/>
        <input type="hidden" v-model="_value" />
        <div class="preview" @click="$refs.fileInput.click()">
            <img v-if="url" :src="url"/>
            <div v-else>画像をアップロード</div>
        </div>
        <div v-if="failed" class="text-danger">{{ errors[0] }}</div>
    </div>
  </ValidationProvider>
</template>

<script>
import { mapActions } from 'vuex';
const REQUIRED = "required";
export default {
  props: {
    label: { type: String, default: "" },
    name: { type: String, require: true },
    required: { type: Boolean, default: false },
    customClass: { type: String, default: "" },
    url: { type: String, default: "" },
    subLabel: { type: String, default: "" },
  },
  computed: {
    _value: {
      get() {
        return this.url;
      },
      set(val) {
        
      },
    },
    _required() {
      return this.required ? this.required : this._rules.includes(REQUIRED);
    },
    _name() {
      return this.label ?? this.name;
    },
    _rules() {
      let rules = this.rules ? this.rules.split("|") : [];
      if (this.required && !rules.includes(REQUIRED)) {
        rules.push(REQUIRED);
      }
      return rules.join("|");
    },
  },
  methods: {
    ...mapActions({
        uploadImage: "upload/uploadImage"
    }),
    async onFileChange(e) {
        const file = e.target.files[0];
        await this.uploadImage({
            file,
            path:"lessons",
            cb: (response) => {
                this.$emit("change", {
                    url: response.preview, 
                    path: response.path
                });
            }
        });
    },
  },
};
</script>
