<template>
  <div>
    <div class="popup-modal" role="dialog" v-if="isVisible">
    <div class="wrapper modal-register">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div></div>
            <h5 class="modal-title">{{ $t('common.login_title') }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="_cancel"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              {{$t('common.login_content')}}
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-between">
            <div>
              <div class="text">会員の方はこちら</div>
              <button type="button" class="btn btn-secondary" @click="signin">
                {{ $t('common.login') }}
              </button>
            </div>
            <div>
              <div class="text">アカウントをお持ちでない方</div>
              <button  type="button" class="btn btn-primary" @click="register">
                {{ $t('common.register') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
 
</template>

<script>
export default {
  props: [
    "isVisible",
    "cancelButton",
  ],
  name: "RegisterModal",
  data() {
    return {};
  },
  methods: {
    _cancel() {
      this.$emit("cancel");
    },
    register(){
      window.$("#mms-sign-up").click()
    },
    signin(){
      window.$("#mms-sign-in").click()
    }
  },
};
</script>
<style scoped>
.modal-content {
    width: 900px;
    height: 283px;
}
</style>