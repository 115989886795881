const routes = [
	{
		path: '/my-page/talk-room',
		component: () => import('@components/sp/layout/mypage/master.vue'),
		children: [
			{
				path: '',
				name: 'my-page/talk-room',
				component: () => import('@/modules/sp/mypage/talk-room/list/index.vue')
			}
		]
	},
	{
		path: '/my-page/talk-room/:id',
		component: () => import('@components/sp/layout/mypage/master.vue'),
		children: [
			{
				path: '',
				name: 'my-page/talk-room/:id',
				component: () => import('@/modules/sp/mypage/talk-room/detail/index.vue')
			}
		]
	},
	{
		path: '/my-page/talk-room-report/:id',
		component: () => import('@components/sp/layout/mypage/master.vue'),
		children: [
			{
				path: '',
				name: 'my-page/talk-room-report/:id',
				component: () => import('@/modules/sp/mypage/talk-room/report/index.vue')
			}
		]
	},
];

export default routes;