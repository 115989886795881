import Vue from "vue";

const types = {
    TEACHER_PROFILE: "TEACHER_PROFILE",
    STUDENT_PROFILE: "STUDENT_PROFILE",
    AVAILABLE_LESSON: "AVAILABLE_LESSON",
    REVIEW_LESSON: "REVIEW_LESSON",
    STUDENT_REVIEW_LESSON: "STUDENT_REVIEW_LESSON",
}

const getters = {
    getTeacherProfile: (state) => state.teacherProfile,
    getStudentProfile: (state) => state.studentProfile,
    getAvailableLesson: (state) => state.availableLesson,
    getReviewLesson: (state) => state.reviewLesson,
    getStudentReviewLesson: (state) => state.studentReviewLesson,
}

const actions = {
    async fetchTeacherProfile({ commit }, params) {
        let id = params.id
        let url = `/api/v1/teacher-profile/${id}`;
        await Vue.prototype.$request.get({ url, params })
            .then(response => commit(types.TEACHER_PROFILE, response.data));
    },
    async fetchStudentProfile({ commit }, params) {
        let id = params.id
        let url = `/api/v1/student-profile/${id}`;
        await Vue.prototype.$request.get({ url, params })
            .then(response => commit(types.STUDENT_PROFILE, response.data));
    },
    async fetchAvailableLesson({ commit }, params) {
        let id = params.id
        let url = `/api/v1/teacher-available-lesson/${id}`;
        const response = await Vue.prototype.$request.get({ url, params });
        commit(types.AVAILABLE_LESSON, response);
    },
    async updateFavorite({ commit }, payload) {
        let url = `/api/v1/teacher-profile/favorite/${payload.id}`;
        return await Vue.prototype.$request.post({url, data: payload})
    },
    async fetchCommentLesson({ commit }, params) {
        let id = params.id
        let url = `/api/v1/teacher-profile/comment/${id}`;
        const response = await Vue.prototype.$request.get({ url, params });
        commit(types.REVIEW_LESSON, response);
    },
    async fetchStudentCommentLesson({ commit }, params) {
        let id = params.id
        let url = `/api/v1/student-profile/comment/${id}`;
        const response = await Vue.prototype.$request.get({ url, params });
        commit(types.STUDENT_REVIEW_LESSON, response);
    },
}

const mutations = {
    [types.TEACHER_PROFILE](state, value) {
        state.teacherProfile = value
    },
    [types.STUDENT_PROFILE](state, value) {
        state.studentProfile = value
    },
    [types.AVAILABLE_LESSON](state, value) {
        state.availableLesson = value
    },
    [types.REVIEW_LESSON](state, value) {
        state.reviewLesson = value
    },
    [types.STUDENT_REVIEW_LESSON](state, value) {
        state.studentReviewLesson = value
    }
}

const state = {
    teacherProfile: null,
    studentProfile: null,
    availableLesson: {
        data: [],
        meta: {}
    },
    reviewLesson: {
        data: [],
        meta: {}
    },
    StudentReviewLesson: {
        data: [],
        meta: {}
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}