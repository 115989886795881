<template>
  <div class="pagination navigation" v-if="length > 0">
    <ul>
      <li v-if="_isShowPrevious">
        <a @click="onPageClick(_value - 1)">&#8592;</a>
      </li>
      <li v-for="page in _pages" :key="page.value" class="mx-1">
        <a
          v-if="page.value"
          @click="onPageClick(page.value)"
          :class="page.value == _value ? 'active' : ''"
        >
          {{ page.label }}
        </a>
        <template v-else>
          {{ page.label }}
        </template>
      </li>
      <li v-if="_isShowNext">
        <a @click="onPageClick(_value + 1)">&#8594;</a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    isShowPrevious: {
      type: Boolean,
      default: true,
    },
    isShowNext: {
      type: Boolean,
      default: true,
    },
    eachSidePage: {
      type: Number,
      default: 3,
    },
    length: {
      type: Number,
      default: 0,
    },
    value: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    _value() {
      return this.value != null && this.value != "" ? this.value : 1;
    },
    _isShowNext() {
      return this.isShowNext && this.value != this.length && this.length >= this.eachSidePage ;
    },
    _isShowPrevious() {
      return this.isShowPrevious && this.value != 1 && this.value > 0 ;
    },
    startPage() {
      if (this.value === this.length) { 
        return this.length - this.eachSidePage + 1;
      }
      if (this.value === 1 || this.value < 1 || this.value == "" || this.value == null) {
        return 1;
      }
      return this.value - 1;
    },
    endPage() {
      return Math.min(this.startPage + this.eachSidePage - 1, this.length);
    },
    _pages() {
      let pages = [];

      if(this.value >= 3){
        pages.push({ label: "1", value: 1 });
        if (this.value >= 4) {
          pages.push({ label: "..." });
        }
      }
      let page = this.startPage;
      if (page < 1) {
        page = 1;
      }
      for (page; page <= this.endPage; page++) {
        pages.push({ label: page, value: page });
      }

      if(pages[pages.length - 1].value != this.length){
        if (this.length - pages[pages.length - 1].value > 1) {
          pages.push({ label: "..." });
        }
        pages.push({ label: this.length, value: this.length });
      }

      //if eachSidePage == Length
      if (this.eachSidePage == this.length) {
        pages = [];
        for (let page = 1; page <= this.length; page++) {
          pages.push({ label: page, value: page });
        }
      }
      return pages;
    },
  },
  methods: {
    onPageClick(page) {
      if (this._value != page) {
        this.$emit("input", page);
      }
    },
  },
};
</script>
