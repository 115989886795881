<template>
  <ValidationObserver class="form" tag="form" @submit.prevent="onSubmit" ref="validationObserver">
    <slot v-bind="{ validate }"/>
  </ValidationObserver>
</template>
<script>
  export default {
    computed: {
      validationObserver() {
        return this.$refs['validationObserver']
      }
    },
    methods: {
      async validate(callback) {
        if (await this.validationObserver.validate()) {
          callback()
        }
      },
      async onSubmit() {
        if (await this.validationObserver.validate()) {
          this.$emit('submit')
        }
      },
      handleError422(error) {
        if (error.response.data.meta.code != 422)
          return;

        let errors = this.responseError({}, error)
        this.validationObserver.setErrors(errors)
      },
      handleError(error) {
        let errors = this.responseError({}, error)
        this.validationObserver.setErrors(errors)
      },
    }
  }
</script>
