const routes = [
	{
		path: '/',
		name: 'top',
		component: () => import('@/modules/pc/page/top/index.vue')
	},
	{
		path: '/information',
		name: 'information',
		component: () => import('@/modules/pc/page/information/index.vue')
	},
	{
		path: '/apply-to-teacher',
		name: 'apply_to_teacher',
		component: () => import('@/modules/pc/page//applyToTeacher/index.vue')
	},
	{
		path: '/to-sell',
		name: 'to-sell',
		component: () => import('@/modules/pc/page/to-sell/index.vue')
	},
	{
		path: '/apply-sell',
		name: 'apply-sell',
		component: () => import('@/modules/pc/page/to-sell/apply-sell.vue')
	},
	{
		path: '/inquiry',
		name: 'inquiry',
		component: () => import('@/modules/pc/page/inquiry-to-admin/index.vue')
	},
	{
		path: '/guide',
		name: 'guide',
		component: () => import('@/modules/pc/page/guide/index.vue')
	},
	{
		path: '/guide2',
		name: 'guide2',
		component: () => import('@/modules/pc/page/guide/guide2.vue')
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('@/modules/pc/page/guide/info.vue')
	},
];

export default routes;
