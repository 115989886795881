import Vue from "vue";

const types = {
  REGISTER_MEMBER: "REGISTER_MEMBER",
  USER_INFO: "USER_INFO"
}

const getters = {
  getUser: (state) => state.user
}

const actions = {
  async registerMember({ commit }, data) {
    Vue.$cookies.set("C2C-MB-ID", data.member_id);
    let member = localStorage.getItem("data_member");
    if(member === JSON.stringify(data)){
      return;
    }
    localStorage.setItem("data_member", JSON.stringify(data));
    let url = "/api/v1/member/register";
    await Vue.prototype.$request.post({ url, data });
  },
  async getUserInfo({ commit }, params = {}) {

    const applyStatus = sessionStorage.getItem("applyStatus");
    if(applyStatus){
      params = {
        ...params,
        applyStatus: new Date().valueOf(),
      }
      sessionStorage.removeItem("applyStatus");
    }
    let token = Vue.$cookies.get("X-MMS-Access-Token");
		if (!token) return;
    let url = "/api/v1/member";
    const response = await Vue.prototype.$request.get({ url, params });
    commit(types.USER_INFO, response.data);
  },
  async verifyStripe({ commit }, cb) {
    sessionStorage.setItem("applyStatus", 1);
    let token = Vue.$cookies.get("X-MMS-Access-Token");
		if (!token) return;
    let url = "/api/v1/member/verify-stripe";
    await Vue.prototype.$request.get({ url })
              .then(response => cb(response))
              .catch(error => cb(error.response));
  }, 
}

const mutations = {
  [types.USER_INFO](state, value) {
    state.user = value
  }
}

const state = {
  user: {
    info: null,
    teacher: null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
