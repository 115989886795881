

const routes = [
	{
		path: '/my-page/lesson',
		component: () => import('@/components/pc/layout/mypage/master.vue'),
		children: [
			{ 
				path: '',
				name: 'my-page/lesson',
				component: () => import('@/modules/pc/mypage/lesson/list/index.vue')
			}
		],
	},
	{
		path: '/my-page/lesson-create',
		component: () => import('@/components/pc/layout/mypage/master.vue'),
		children: [
			{
				path: '',
				name: 'my-page/lesson-create',
				component: () => import('@/modules/pc/mypage/lesson/create/index.vue'),
			},
		]
	},
	{
		path: '/my-page/lesson-edit/:lessonId',
		component: () => import('@/components/pc/layout/mypage/master.vue'),
		children: [
			{
				path: '',
				name: 'my-page/lesson-edit',
				component: () => import('@/modules/pc/mypage/lesson/edit/index.vue'),
			}
		]
	},
	{
		path: '/my-page/lesson-preview/:id',
		name: '/my-page/lesson-preview/:id',
		component: () => import('@/modules/pc/mypage/lesson/preview/index.vue'),
	},
];

export default routes;