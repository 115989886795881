import Vue from "vue"

export const generateErrorNotify = (title, desc) => {
  console.log(title, desc)
}

export const handleError = error => {
  if (error.status === 401) {
    const allCookies = Vue.$cookies.keys()
    allCookies.forEach(cookie => Vue.$cookies.remove(cookie))
    localStorage.setItem("data_member", null);
    Vue.$cookies.remove("error")
    generateErrorNotify("Unauthorized action")

  } else if (error.status === 403) {
    generateErrorNotify("Forbidden action")

  } else if (error.status === 404) {
    generateErrorNotify("Request Not Found")

  } else if (error.status === 422) {
    return false

  } else if (error.status >= 500 && error.status <= 599) {
    generateErrorNotify("Internal Server Error")

  } else {
    generateErrorNotify("There is something error")
  }
}
