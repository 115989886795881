import Vue from "vue";

const types = {
    LIST_TEACHER_LESSONS: "LIST_TEACHER_LESSONS",
}

const getters = {
    "listTeacherLesson": () => state.list,
}

const actions = {
    async getTeacherLessons({ commit }, params) {
        let url = "/api/v1/teacher-lesson";
        let response = await Vue.prototype.$request
            .get({ url, params });
        commit(types.LIST_TEACHER_LESSONS, response);
    },
}

const mutations = {
    [types.LIST_TEACHER_LESSONS](state, value) {
        state.list = {
            data: value.data.items,
            pagination: value.data.pagination
        }
    },
}

const state = {
    list: {
        data: [],
        pagination: {
            page: 1,
            last: 1
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
