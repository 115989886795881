<template>
    <ul class="breadcrumb">
        <li>
            <router-link to="/">{{$t("menu.breadcrumb.home")}}</router-link>
        </li>
        <li v-for="(item, index) in breadcrumb" :key="index">
            <router-link :to="item.link">{{item.title}}</router-link>
        </li>
    </ul>
</template>

<script>
export default {
    name: "BreadCrumb",
    props: {
        breadcrumb: Array
    }
}
</script>
