<template>
  <div :class="`category-item ${length == 0 ? 'main-menu' : ''}`">
    <div
      class="label text-wrap text-break"
      :style="length == 0 ? '' : { paddingLeft: length * 15 + 15 + 'px' }"
    >
      <span :class="`${length == 0 ? 'menu-item' : 'menu-sub-item'}`">
        <span class="icon-angle" v-if="length == 0">&#8250;</span>
        <router-link 
          data-toggle="offcanvas" 
          data-target=".navmenu"
          :to="{ name: 'lesson.category.list', params: {slug: slug_location}}">{{ label }}</router-link>
      </span>
    </div>
      <category-menu
        v-for="(item, index) in data"
        :key="index"
        :data="item.children"
        :label="item.name"
        :slug="item.slug"
        :slug_location="item.slug_location_arr"
        :length="length + 1"
      />
  </div>
</template>

<script>
export default {
  name: "category-menu",
  props: {
    data: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    slug_location: {
      type: Array,
      required: true
    },
    length: {
      type: Number,
      required: true
    }
  },
};
</script>