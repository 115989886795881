const routes = [
	{
		path: '/my-page/inquiry',
		component: () => import('@/components/sp/layout/mypage/master.vue'),
		children: [
			{
				path: '',
				name: 'my-page/inquiry',
				component: () => import('@/modules/sp/mypage/inquiry/list/index.vue')
			}
		]
	},
	{
		path: '/my-page/inquiry/:id',
		component: () => import('@/components/sp/layout/mypage/master.vue'),
		children: [
			{
				path: '',
				name: 'my-page/inquiry/:id',
				component: () => import('@/modules/sp/mypage/inquiry/detail/index.vue')
			}
		]
	},
	{
		path: '/my-page/inquiry-report/:id',
		component: () => import('@/components/sp/layout/mypage/master.vue'),
		children: [
			{
				path: '',
				name: 'my-page/inquiry-report/:id',
				component: () => import('@/modules/sp/mypage/inquiry/report/index.vue')
			}
		]
	},
];

export default routes;