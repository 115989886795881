import Vue from "vue";

const types = {
    LESSON_DETAIL: "LESSON_DETAIL",
    LESSON_COMMENTS: "LESSON_COMMENTS",
    POP_UP: "POP_UP"
}

const getters = {
    getLessonDetail: (state) => state.lesson_detail,
    getLessonComments: (state) => state.lesson_comments,
    getPopUpState: (state) => state.pop_up
}

const actions = {
    async fetchLessonDetail({ commit }, payload) {
        let url = `/api/v1/lesson-detail/${payload.lessonId}`;
        const response = await Vue.prototype.$request.post({ url, data: payload });
        commit(types.LESSON_DETAIL, response.data);
    },

    async fetchComments({ commit }, {lessonId, params}) {
        let url = `/api/v1/lesson-detail/comments/${lessonId}`;
        const response = await Vue.prototype.$request.get({ url, params });
        commit(types.LESSON_COMMENTS, response);
    },
    async updateFavorite({ commit }, payload) {
        let url = `/api/v1/lesson-detail/favorite/${payload.parent_id}`
        const response = await Vue.prototype.$request.post({ url, data: payload });
        return response
    },
    setPopup({ commit }) {
        commit(types.POP_UP, !state.pop_up)
    },
    async getLessonInquiry({ commit }, {params, cb}) {
        let url = `/api/v1/inquiry-teacher/lesson-detail`
        await Vue.prototype.$request.get({ url, params })
                .then(response => cb(response))
                .catch(error => cb(error.response));
    },
    async inquiryToTeacher({ commit }, {data, cb}) {
        let url = `/api/v1/inquiry-teacher/lesson-detail`
        await Vue.prototype.$request.post({ url, data: data })
                .then(response => cb(response))
                .catch(error => cb(error.response));
    },
    async verifyItems({ commit }, {params, cb}) {
        let url = `/api/v1/verify-items`
        await Vue.prototype.$request.get({ url, params })
                .then(response => cb(response))
                .catch(error => cb(error.response));
    },
    async confirmPurchase({ commit }, {data, cb}) {
        let url = `/api/v1/comfirm-purchase`
        await Vue.prototype.$request.post({ url, data })
                .then(response => cb(response))
                .catch(error => cb(error.response));
    },
    async validateItems({ commit }, data) {
        let url = `/api/v1/validate-items`
        return await Vue.prototype.$request.post({ url, data })
                .then(response => response)
                .catch(error => error.response);
    },
}

const mutations = {
    [types.LESSON_DETAIL](state, value) {
        state.lesson_detail = value
    },
    [types.LESSON_COMMENTS](state, value) {
        state.lesson_comments = value
    },
    [types.POP_UP](state, value) {
        state.pop_up = value
    }
}

const state = {
    lesson_detail: null,
    lesson_comments: null,
    pop_up: false
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
