import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import pc_routes from './routes/pc'
import sp_routes from './routes/sp'
import * as mixin from '../../mixin'

Vue.use(Router)
Vue.use(Meta)
let router;
if (mixin.default.methods.isMobile()) {
	router = new Router({
		mode: 'history',
		base: process.env.VUE_APP_BASE_URL,
		routes: [...sp_routes]
	})
} else {
	router = new Router({
		mode: 'history',
		base: process.env.VUE_APP_BASE_URL,
		routes: [...pc_routes]
	})
}

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

router.beforeEach(async (to, from, next) => {

	const token = Vue.$cookies.get("X-MMS-Access-Token");
	if (to.path.indexOf("my-page") > -1 && !token) {
		window.location.href = "/";
	}
	let middlewares = (to?.meta && to?.meta.middlewares) ? to.meta.middlewares : [];
	if (middlewares.length > 0) {
		for (let index = 0; index < middlewares.length; index++) {
			const middleware = middlewares[index];
			await middleware({ to, from, next, router })
		}
	}

	window.scrollTo(0, 0)
	return next()
})

router.setQuery = function (params) {
	let query = {}
	let oldQuery = this.currentRoute.query;

	let resetPage = false;
	for (let key in params) {
		if (params[key]) {
			query[key] = params[key]
		}
		if (key != 'page' && key != 'limit' && resetPage == false) {
			let oldParam = oldQuery[key] ? oldQuery[key] + '' : '';
			let newParam = params[key] ? params[key] + '' : '';

			if (oldParam != newParam) {
				resetPage = true;
			}
		}
	}
	if (resetPage) {
		delete query['page'];
		delete query['limit'];
	}

	this.push({ query }).catch(() => { })
}

router.getQuery = function (filter) {
	let query = router.currentRoute.query;
	let queryValue = null
	let filterValue = null
	let isFilterValueArray = false

	for (let key in filter) {
		queryValue = query[key]
		filterValue = filter[key]
		isFilterValueArray = Array.isArray(filterValue)

		if (queryValue) {
			if (isFilterValueArray) {
				if (Array.isArray(queryValue)) {
					filter[key] = queryValue
				} else {
					filter[key] = [queryValue]
				}
			} else {
				if (typeof filterValue == 'number') {
					filter[key] = queryValue - 0
				} else {
					filter[key] = queryValue + ''
				}
			}
		} else {
			if (isFilterValueArray) {
				filter[key] = []
			} else {
				filter[key] = ''
			}
		}
	}
	return filter
}

export default router
