import i18n from '@/services/i18n'
import * as Validator from 'vee-validate'

// default locale
let _locale = JSON.parse(localStorage.getItem('locale')) ||
  navigator.language.slice(0, 2) ||
  'en';

const getters = {
  locale: (state) => state.locale
}

const actions = {
  setLocale({ commit }, payload) {
    i18n.locale = payload
    Validator.localize(payload)
    window.localStorage.setItem('locale', JSON.stringify(payload))
    commit("SET_LOCALE", payload)
  }
}

const mutations = {
  ["SET_LOCALE"](state, value) {
    state.locale = value
  }
}

const state = {
  locale: _locale
}

export default {
  state,
  getters,
  actions,
  mutations
}
