const routes = [

	{
		path: '/my-page',
		component: () => import('@components/sp/layout/mypage/master.vue'),
		children: [
			{
				path: '',
				name: 'my-page',
				component: () => import('@/modules/sp/mypage/dashboard/index.vue')
			},
			{
				path: 'list-information',
				name: 'my-page-list-information',
				component: () => import('@/modules/sp/mypage/information/index.vue')
			},
			{
				path: 'introduction-edit',
				name: 'my-page-introduction-edit',
				component: () => import('@/modules/sp/mypage/introduction/introduction-edit.vue')
			},
			{
				path: 'favorite',
				name: 'my-page/favorite',
				component: () => import('@/modules/sp/mypage/favorite/index.vue')
			},
			{
				path: 'profile',
				name: 'my-page/profile',
				component: () => import('@/modules/sp/mypage/profile/index.vue')
			},
			{
				path: 'list-lesson',
				name: 'my-page/list-lesson',
				component: () => import('@/modules/sp/mypage/lesson-list/index.vue'),
			},
		]
	},

];

export default routes;