import Vue from 'vue';
import axios from 'axios';
import { handleError } from './handleError.js';

const MMS_API = process.env.VUE_APP_MMS_API;
const MMS_SERVICE_KEY = process.env.VUE_APP_MMS_SERVICE_KEY;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.response.use(res => {
	return res.data;
}, e => {
	return Promise.reject(e);
});

class Request {
	async post({ url, data }) {
		return this._request({ url, method: 'post', data });
	}

	async get({ url, params }) {
		return this._request({ url, method: 'get', params });
	}

	async put({ url, data }) {
		return this._request({ url, method: 'put', data });
	}

	async delete({ url, data }) {
		return this._request({ url, method: 'delete', data });
	}

	async _request({ url, method, params = null, data = null, headers }) {
		if (!headers) {
			headers = {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			};
		}

		const memberId = Vue.$cookies.get('C2C-MB-ID');
		if (memberId) {
			headers['X-MEMBER-ID'] = memberId;
		}
		headers['X-Client-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

		try {
			return await axios({
				url,
				method,
				headers,
				data,
				params
			});

		} catch (error) {
			const errorResponse = Vue.$cookies.get('error');
			if (errorResponse === 'false' || errorResponse === null) {
				Vue.$cookies.set('error', 'true');
			}
			if (error && error.response) {
				handleError(error.response);
			}
			throw error;
		}
	}

	async _requestMMS({ url, method, params = null, data = null, headers }) {

		if (!headers) {
			headers = {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			};
		}
		let token = Vue.$cookies.get("X-MMS-Access-Token");
		if (!token) return;
		headers["Authorization"] = `Bearer ${token}`;
		headers["X-MMS-Service-Key"] = MMS_SERVICE_KEY;
		url = MMS_API + url;
		try {
			return await axios({
				url,
				method,
				headers,
				data,
				params
			});

		} catch (error) {
			const errorResponse = Vue.$cookies.get('error');
			if (errorResponse === 'false' || errorResponse === null) {
				Vue.$cookies.set('error', 'true');
			}
			if (error && error.response) {
				handleError(error.response);
			}
			throw error;
		}
	}

	async _requestApiMMS({ url, method, params = null, data = null, headers }) {
		if (!headers) {
			headers = {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			};
		}
		let token = Vue.$cookies.get("X-MMS-Access-Token");
		if (!token) return;
		headers["X-MMS-Access-Token"] = token;
		headers["X-MMS-Locale"] = Vue.$cookies.get("X-MMS-Locale");
		
		try {
			return await axios({
				url,
				method,
				headers,
				data,
				params
			});

		} catch (error) {
			const errorResponse = Vue.$cookies.get('error');
			if (errorResponse === 'false' || errorResponse === null) {
				Vue.$cookies.set('error', 'true');
			}
			if (error && error.response) {
				handleError(error.response);
			}
			throw error;
		}
	}
}

export default Request;
