import Vue from 'vue';
import {
  ValidationObserver,
  ValidationProvider,
  extend,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import i18n from "@services/i18n.js";
import moment from 'moment';


// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});


extend('url', {
  validate(value) {
    /**
     * URL is valid follow by this pattern (https://www.car.com...) || (https://car.com...)
     */
    const regex = new RegExp("^https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)");
    return regex.test(value)
  },
  message: i18n.t("validation.vee-validate.url"),
})

extend('required', {
  message: i18n.t("validation.vee-validate.required"),
});

extend("date", {
  validate(value) {
    return moment(value,"YYYY/MM/DD HH:mm",true).isValid();
  },
  message: i18n.t("validation.vee-validate.date"),
}),

extend("max", {
  message: i18n.t("validation.vee-validate.max"),
});

extend("min", {
  message: i18n.t("validation.vee-validate.min"),
});

extend("min_value", {
  message: i18n.t("validation.vee-validate.min-price"),
});

extend('email', {
  message: i18n.t("validation.vee-validate.email"),
});


Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

