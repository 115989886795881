const routes = [

	{
		path: '/my-page',
		component: () => import('@components/pc/layout/mypage/master.vue'),
		children: [
			{
				path: '',
				name: 'my-page',
				component: () => import('@/modules/pc/mypage/dashboard/index.vue'),
			},
			{
				path: 'list-information',
				name: 'my-page-list-information',
				component: () => import('@/modules/pc/mypage/information/index.vue')
			},
			{
				path: 'introduction-edit',
				name: 'my-page-introduction-edit',
				component: () => import('@/modules/pc/mypage/introduction/introduction-edit.vue')
			},
			{
				path: 'favorite',
				name: 'my-page/favorite',
				component: () => import('@/modules/pc/mypage/favorite/index.vue')
			},
			{
				path: 'profile',
				name: 'my-page/profile',
				component: () => import('@/modules/pc/mypage/profile/index.vue')
			},
			{
				path: 'list-lesson',
				name: 'my-page/list-lesson',
				component: () => import('@/modules/pc/mypage/lesson-list/index.vue'),
			}
		]
	}
];
export default routes;