import Vue from "vue";

const types = {
    CATEGORY_MENU: 'CATEGORY_MENU',
    POPULAR_CATEGORY: 'POPULAR_CATEGORY',
}

const getters = {
    getCategoryMenu: (state) => state.categoryMenu,
    getPopularCategory: (state) => state.popularCategory,
}

const actions = {
    async fetchCategoryMenus({ commit }, data) {
        let url = "/api/v1/lesson-category/category-menu";
        const response = await Vue.prototype.$request.get({ url, data });
        commit(types.CATEGORY_MENU, response);
    },
    async fetchPopularCategory({ commit }, data) {
        let url = "/api/v1/lesson-category/popular-category";
        const response = await Vue.prototype.$request.get({ url, data });
        commit(types.POPULAR_CATEGORY, response);
    }
}

const mutations = {
    [types.CATEGORY_MENU](state, categoryMenu) {
        state.categoryMenu = categoryMenu;
    },
    [types.POPULAR_CATEGORY](state, popularCategory) {
        state.popularCategory = popularCategory;
    }
}

const state = {
    categoryMenu: {
        data: [],
        meta: {},
    },
    popularCategory: {
        data: [],
        meta: {},
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
