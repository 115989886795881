import Vue from "vue";

const types = {}
const getters = {}
const actions = {
    async applyToTeacher({ commit }, params) {
        sessionStorage.setItem("applyStatus", 1);
        let url = '/api/v1/member/apply-to-teacher'
        const response = await Vue.prototype.$request.post({ url, data: params })
                                .then(response => response)
                                .catch(error => error.response);
        return response;
    }
}
const mutations = {}
const state = {
}

export default {
    namespaced: true,
    getters,
    actions,
    mutations,
    state
}