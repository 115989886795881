import Button from './button/index.vue';
import InlineInput from './input/inline_input.vue';
import TextArea from './input/textarea.vue';
import Pagination from './paginate/paginate.vue';
import Radio from './radio';
import RadioInline from './radio/inline';
import FormView from './form';
import Popup from './popup_modal';
import Alert from './alert';
import Select from './select';
import SelectInline from './select/inline';
import UploadImage from './upload/upload-image';
import UploadImageInline from './upload/upload-image-inline';
import UploadBaseImage from './upload/upload-base-image';
import UploadBaseImageInline from './upload/upload-base-image-inline';
import Input from './input/input.vue';
import InlineTextArea from './input/inline_textarea.vue';
import AttachmentFile from './upload/attachment-file';
import RegisterModal from './register_modal';
import MMS from './mms'
import StarRating from 'vue-star-rating'
import mDatePicker from './multi-date-picker';
import RegisterModalSp from './register_modal/index_sp';
import ReminderLessonModal from './reminder_modal';
import Breadcrumb from './breadcrumb';
import MetaTags from './metatags';
import rangeDatePicker from './range-date-picker/index.vue';

const Components = {
  Button,
  InlineInput,
  TextArea,
  Pagination,
  Radio,
  RadioInline,
  FormView,
  Popup,
  Alert,
  Select,
  SelectInline,
  UploadImage,
  UploadImageInline,
  UploadBaseImage,
  UploadBaseImageInline,
  Input,
  InlineTextArea,
  AttachmentFile,
  RegisterModal,
  MMS,
  StarRating,
  mDatePicker,
  RegisterModalSp,
  ReminderLessonModal,
  Breadcrumb,
  MetaTags,
  rangeDatePicker,
};

const ComponentsInit = {
  install(Vue) {
    Object.keys(Components).forEach((name) => {
      Vue.component(name, Components[name]);
    });
  }
};

export default ComponentsInit;
