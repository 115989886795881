<template>
  <div>
    <div class="row">
        <div class="col-md-3 frm-l">
            <label class="lbl">{{label}}</label>
            <span v-if="required" class="text-danger">*</span>
        </div>
        <div class="col-md-9 frm-r radio">
            <div class="row">
                <div v-for="(item, index) in options" :key="index" :class="preLine ? 'col-12' : 'col-3 col-item'">
                    <input type="radio" :id="item.value" v-model="_value" :value="item.value" :disabled="isDisabled"/>
                    <label :for="item.value">{{ item.name }}</label>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
const REQUIRED = "required";
export default {
  props: {
    label: { type: String, default: "" },
    name: { type: String, require: true },
    required: { type: Boolean, default: false },
    value: { require: true, default: "" },
    options: { type: Array, default: null },
    customClass: { type: String, default: "" },
    isDisabled: { type: Boolean, default: false },
    preLine: { type: Boolean, default: false },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    _required() {
      return this.required ? this.required : this._rules.includes(REQUIRED);
    },
    _name() {
      return this.name ?? this.label;
    },
    _rules() {
      let rules = this.rules ? this.rules.split("|") : [];
      if (this.required && !rules.includes(REQUIRED)) {
        rules.push(REQUIRED);
      }
      return rules.join("|");
    },
  },
};
</script>

<style>
</style>