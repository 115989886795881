<template>
  <button @click="$emit('handleClick')">{{ name }}</button>
</template>

<script>
export default {
  props: {
    name: String,
  },
};
</script>

<style>
</style>