<template>
  <ValidationProvider
    tag="div"
    v-bind="$attrs"
    :rules="_rules"
    v-slot="{ errors, failed }"
    :name="_name"
    ref="validation_observe"
  >
    <div class="">
      <label class="label" :for="label">{{ label }}</label>
      <span v-if="_required" class="text-danger">*</span>
      <br />
      <textarea
        :rows="rows"
        :type="type"
        :value="_value"
        :placeholder="placeholder"
        v-model="_value"
        :disabled="isDisabled"
      />
      <div v-if="failed" class="text-danger">{{ errors[0] }}</div>
    </div>
  </ValidationProvider>
</template>

<script>
const REQUIRED = "required";
export default {
  props: {
    label: { type: String, default: "" },
    name: { type: String, require: true },
    required: { type: Boolean, default: false },
    type: { type: String, default: "" },
    value: { require: true, default: "" },
    placeholder: { type: String, default: "" },
    customClass: { type: String, default: "" },
    isDisabled: { type: Boolean, default: false },
    rows: { type: Number, default: 5 },
    errorLabel: { type: String, default: "" },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    _required() {
      return this.required ? this.required : this._rules.includes(REQUIRED);
    },
    _name() {
      return this.errorLabel ? this.errorLabel : (this.label ?? this.name);
    },
    _rules() {
      let rules = this.rules ? this.rules.split("|") : [];
      if (this.required && !rules.includes(REQUIRED)) {
        rules.push(REQUIRED);
      }
      return rules.join("|");
    },
  },
};
</script>

<style scoped>
textarea {
}
</style>