<template>
  <div>
    <router-view />
  </div>
</template>

<script>
  export default {
    name: "index",
    computed: {

    },
    created() {

    },
    watch: {

    },
  }
</script>
