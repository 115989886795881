import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './services/router'
import Request from './services/axios'
import VueCookies from 'vue-cookies'
import i18n from './services/i18n'
import components from '@/components/shared'
import VueMeta from 'vue-meta'
import * as config from './services/config'
require('./services/vee-validate/index')

import loading from 'vuejs-loading-screen';
Vue.use(loading, {
  bg: '#cccccca3',
  slot: `
    <div class="px-5 py-3 bg-gray-800 rounded">
      <h3 class="text-3xl text-white"><i class="fas fa-spinner fa-spin"></i> お待ちください... </h3>
    </div>
  `
});

/** global mixins */
import mixin from './mixin'
Vue.mixin(mixin);

// set global component
Vue.use(components)
Vue.use(VueCookies)

Vue.config.productionTip = false
Vue.prototype.$request = new Request();
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

// compile scss
import "@/assets/scss/index.scss"
window.$ = window.jQuery = require('jquery')
import Popper from 'popper.js';
window.Popper = Popper;
require('bootstrap')
require('jasny-bootstrap')
Vue.prototype.$ASSET_URL = config.default.ASSET_URL;
Vue.prototype.$MMS_API = config.default.MMS_API;
Vue.config.ignoredElements = [/^mms-auth/];
require('./services/mms')
new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
  created() {
    store.dispatch('setLocale', 'ja')
  }
}).$mount('#app');
