import Vue from "vue";

const types = {
    LIST_LESSONS: "LIST_LESSONS",
}

const getters = {
    "listLesson": () => state.list
}

const actions = {
    async createLesson({ commit }, {data, cb}) {
        let url = "/api/v1/lesson";
        await Vue.prototype.$request
            .post({ url, data })
            .then(response =>  cb(response))
            .catch(error =>  cb(error.response));
    },
    async getLessons({ commit }, params) {
        let url = "/api/v1/lesson";
        let response = await Vue.prototype.$request
            .get({ url, params });
        commit(types.LIST_LESSONS, response);
    },
    async getLessonById({ commit }, {id, cb}) {
        let url = `/api/v1/lesson/${id}/edit`;
        await Vue.prototype.$request
            .get({ url })
            .then(response =>  cb(response))
            .catch(error =>  cb(error.response));
    },
    async updateLesson({ commit }, {data, id, cb}) {
        let url = `/api/v1/lesson/${id}`;
        await Vue.prototype.$request
            .put({ url, data })
            .then(response =>  cb(response))
            .catch(error =>  cb(error.response));
    },
    async storePreviewLesson({ commit }, { data,  cb}) {
        let url = "/api/v1/lesson-preview";
        await Vue.prototype.$request
            .post({ url, data })
            .then(response =>  cb(response))
            .catch(error =>  cb(error.response));
    },
    async getPreviewLesson({ commit }, { params, cb }) {
        let url = "/api/v1/lesson-preview";
        await Vue.prototype.$request
            .get({ url, params })
            .then(response =>  cb(response))
            .catch(error =>  cb(error.response));
    },
}

const mutations = {
    [types.LIST_LESSONS](state, value) {
        state.list = {
            data: value.data,
            pagination: value.meta.pagination
        }
    }
}

const state = {
    lesson: {},
    list: {
        data: [],
        pagination: {
            page: 1,
            last: 1
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
