import Vue from "vue";

const types = {
    LIST_SELL_LESSONS: "LIST_SELL_LESSONS",
    LIST_LESSON_TOP: "LIST_LESSON_TOP"
}

const getters = {
    "listSellLesson": () => state.list,
    "lessonTop": () => state.lessonTop
}

const actions = {
    async getSellLessons({ commit }, params) {
        let url = "/api/v1/sell-lesson";
        let response = await Vue.prototype.$request
            .get({ url, params });
        commit(types.LIST_SELL_LESSONS, response);
    },
    async getLessonTop({ commit }, {params, cb}) {
        let url = "/api/v1/sell-lesson-top";
        let response = await Vue.prototype.$request
            .get({ url, params });
        commit(types.LIST_LESSON_TOP, response);
    },
}

const mutations = {
    [types.LIST_SELL_LESSONS](state, value) {
        state.list = {
            data: value.data.items,
            pagination: value.data.pagination
        }
    },
    [types.LIST_LESSON_TOP](state, value) {
        state.lessonTop = value.data
    },
}

const state = {
    list: {
        data: [],
        pagination: {
            page: 1,
            last: 1
        }
    },
    lessonTop: {
        lesson: {
            experts: [],
            availableBooks: [],
            ranks: [],
            rankCategories: [],
        },
        teacher: {
            experts: [],
            availableBooks: [],
            ranks: [],
        },
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
