<template>
    <header>
        <div class="header">
            <div class="main top-menu">
                <div class="logo pt-1" style="max-width: 160px">
                    <router-link to="/">
                        <img src="/img/main_logo.png" alt="site-logo"/>
                    </router-link>
                </div>
                <div class="user">
                    <router-link class="item" to="/about">
                        {{$t("menu.usage_guide")}}
                    </router-link>
                    <template v-if="user.info">
                        <Notification :notification="notification"/>
                        <router-link class="item" :to="{name: 'my-page'}">
                            {{$t("menu.my_page")}}
                        </router-link>
                    </template>
                    <button type="button" class="navbar-toggle btn btn-menu" data-toggle="offcanvas" data-target=".navmenu">
                        &#x2630;
                    </button>
                    <NavRight />
                </div>
            </div>
        </div>
        <nav class="navbar bg-light">
            <div class="navbar-main">
                <ul class="navbar-nav mr-auto mt-lg-0">
                    <li class="nav-item">
                        <router-link to="/c/all">
                            {{$t("menu.search_category")}}
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/c/all?tab=teacher&teacher_type=expert&type=category">
                            {{$t("menu.search_by_expert")}}
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/rank/all">
                            {{$t("menu.ranking")}}
                        </router-link>
                    </li>
                </ul>
                <ul class="navbar-nav ml-auto mt-lg-0 pr-0">
                    <li class="search">
                        <button @click="handleSearch(true)" class="btn-search"></button>
                    </li>
                </ul>
                <ul class="navbar-nav ml-auto mt-lg-0">
                    <li class="nav-item pr-0">
                        <a href="javascript:void(0)" @click="_toSell">
                            {{$t("menu.to_sell")}}
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <RegisterModalSp
            :isVisible="this.isVisible"
            @cancel="actionCancel"
        />
        <Popup
            :isVisible="isSearching"
            @cancel="handleSearch(false)"
            :hideAllButton="true"
            class="modal-search"
        >
            <form class="search" @submit="_search">
                <input type="text" v-model="keyword"/>
                <button class="btn-search"></button>
            </form>
        </Popup>
    </header>
</template>

<script>
import Notification from './components/notification.vue';
import NavRight from "@/components/sp/nav/nav-right.vue";
export default {
    data(){
        return {
            isVisible: false,
            keyword: "",
            isSearching: false
        }
    },
    props:{
        user: {},
        notification: {},
    },
    components: {
        Notification,
        NavRight
    },
    methods: {
        _toSell(){
            let { info, stripe } = this.user;
            if(!info){
                this.isVisible = true;
                return;
            }
            if(info.role == "teacher" &&
                stripe.is_account_verify == true){
                this.$router.push("/my-page/lesson");
            }else{
                this.$router.push("/to-sell");
            }
        },
        actionCancel(){
            this.isVisible = false;
        },
        _login(){
            window.$("#mms-sign-in").click()
        },
        _register(){
            window.$("#mms-sign-up").click()
        },
        _search(e){
            e.preventDefault();
            this.isSearching = false;
            this.$router.push("/c/all?search="+this.keyword)
        },
        handleSearch(status){
            this.isSearching = status;
        }
    },
};
</script>
<style scoped>
header {
    position: sticky;
    top: 4rem;
    z-index: 999;
    background-color: #FFFFFF;
}
.header .user .item {
    font: normal normal bold 11px/24px var(--unnamed-font-family-hiragino-sans);
    letter-spacing: 0.11px;
}
</style>