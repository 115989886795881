import Vue from "vue";


const getters = {

}

const actions = {
    async updateIntroduction({ commit }, { data, id, cb }) {
        let token = Vue.$cookies.get("X-MMS-Access-Token");
        if (!token) return;
        let url = `/api/v1/mms/user/${id}/update-introduction`;
        await Vue.prototype.$request._requestApiMMS({
            url,
            method: "PUT",
            data
        })
            .then(response => cb(response))
            .catch(error => cb(error.response.data));
    },

    async fetchUserInformation({ commit }, { id, cb }) {
        let url = `/api/v1/user/${id}`;
        await Vue.prototype.$request
            .get({ url })
            .then(response => cb(response))
            .catch(error => cb(error.response));
    },
    async getMMSValueCustomFields({ commit }, { cb }) {
        let url = "/api/v1/mms/user/value-custom-fields";
        await Vue.prototype.$request._requestApiMMS({ url })
            .then(response => cb(response))
            .catch(error => cb(error.response));
    },
}

const mutations = {

}

const state = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}