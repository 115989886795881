<template>
    <div :class="customClass">
        <input style="display: none" ref="fileInput" type="file" @change="onFileChange" accept="csv,txt,xlx,xls,pdf,doc,docx,jpeg,jpg,png,gif"/>
        <button type="button" v-if="isFile" @click="handleRemove" class="btn btn-sm btn-danger btn-remove pull-right">
          <i class="fa fa-remove"></i>
        </button>
        <div class="preview attachment-preview text-left p-1 bg-gray" @click="$refs.fileInput.click()">
            <img v-if="preview" :src="preview"/>
            <label class="file-name" v-else-if="name" :src="name">{{name}}</label>
            <div v-else>
              <div v-if="icon">
                <i class="fa fa-paperclip"></i>
              </div>
              <div v-else> 
                ファイルを添付する
              </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data(){
    return {
      preview: null,
      name: null,
      isFile: false
    }
  },
  props: {
    customClass: { type: String, default: "attachment" },
    removeFile: { type: Boolean, default: false },
    icon: { type: Boolean, default: false }
  },
  methods: {
    async onFileChange(e) {
        const file = e.target.files[0];
        this.name    = file.name;
        let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png','image/gif'];
        if(allowedExtension.includes(file.type)){
           this.preview = URL.createObjectURL(file);
        }
        this.isFile = true;
        this.$emit("change", file);
    },
    handleRemove(){
      this.preview = null;
      this.name = null;
      this.isFile = false;
      this.$emit("change", null);
    }
  },
  watch: {
    removeFile(status){
      if(status){
        this.handleRemove();
      }
    }
  }
};
</script>
