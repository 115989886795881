<template>
  <div class="form-check-inline">
    <label :for="label">{{ label }}</label>
    <span v-if="_required" class="text-danger">*</span>
    <div class="row">
      <div v-for="(item, index) in options" :key="index" :class="preLine ? 'col-12' : 'col-3'">
        <input type="radio" :id="item.value" v-model="_value" :value="item.value" />
        <label :for="item.value">{{ item.name }}</label>
      </div>
    </div>
  </div>
</template>

<script>
const REQUIRED = "required";
export default {
  props: {
    label: { type: String, default: "" },
    name: { type: String, require: true },
    required: { type: Boolean, default: false },
    value: { require: true, default: "" },
    options: { type: Array, default: null },
    customClass: { type: String, default: "" },
    isDisabled: { type: Boolean, default: false },
    preLine: { type: Boolean, default: false },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    _required() {
      return this.required ? this.required : this._rules.includes(REQUIRED);
    },
    _name() {
      return this.name ?? this.label;
    },
    _rules() {
      let rules = this.rules ? this.rules.split("|") : [];
      if (this.required && !rules.includes(REQUIRED)) {
        rules.push(REQUIRED);
      }
      return rules.join("|");
    },
  },
};
</script>

<style>
</style>