<template>
    <ValidationProvider
        tag="div"
        v-bind="$attrs"
        :rules="_rules"
        v-slot="{ errors, failed }"
        :name="_name"
        ref="validation_observe"
        >
        <div class="upload">
            <div class="row">
                <div class="col-md-3 frm-l">
                <label class="lbl" :for="label">{{ label }}</label>
                <span v-if="_required" class="text-danger">*</span>
                <br/>
                <b v-if="subLabel">{{ subLabel }}</b>
                </div>
                <div class="col-md-9 frm-r">
                    <input style="display: none" ref="fileInput" type="file" @change="onFileChange" accept="image/*"/>
                    <input type="hidden" v-model="_value" />
                    <div class="preview" @click="$refs.fileInput.click()" id="drop-zone" @dragover="handleDragOver" @drop="handleDrop">
                        <img v-if="url" :src="url"/>
                        <div  class="choose" v-else>
                            <div>
                                <div class="button">画像をアップロード</div>
                            </div>
                            <div>
                                <small>または、ここに画像をドロップしてください</small>
                            </div>
                        </div>
                    </div>
                    <div v-if="failed" class="text-danger">{{ errors[0] }}</div>
                </div>
            </div>
        </div>
    </ValidationProvider>
</template>

<script>
const REQUIRED = "required";
export default {
    props: {
        label: { type: String, default: "" },
        name: { type: String, require: true },
        required: { type: Boolean, default: false },
        customClass: { type: String, default: "" },
        url: { type: String, default: "" },
        subLabel: { type: String, default: "" },
    },
    computed: {
        _value: {
            get() {
                return this.url;
            },
            set(val) {
                
            },
            },
            _required() {
            return this.required ? this.required : this._rules.includes(REQUIRED);
            },
            _name() {
            return this.name ?? this.label;
            },
            _rules() {
            let rules = this.rules ? this.rules.split("|") : [];
            if (this.required && !rules.includes(REQUIRED)) {
                rules.push(REQUIRED);
            }
            return rules.join("|");
        },
    },
    methods: {
        async onFileChange(e) {
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                let rawImg;
                reader.onloadend = () => {
                    rawImg = reader.result;
                    this.$emit("change", {
                        url: URL.createObjectURL(file),
                        path: rawImg,
                    });
                }
                reader.readAsDataURL(file);
            }
        },
        handleDragOver(event) {
            event.preventDefault();
        },
        handleDrop(event) {
            event.preventDefault();
            const files = event.dataTransfer.files;
            if (this.isValidImage(files[0])) {
                this.onFileChange({
                    target: {
                        files: files
                    }
                });
            }
        }, 
        isValidImage(file) {
            if (!file) return false;
            const acceptedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
            const extension = file.name.split('.').pop().toLowerCase();
            return acceptedExtensions.includes(extension);
        }
    },
};
</script>
