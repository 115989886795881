<template>
 <ValidationProvider
    tag="div"
    v-bind="$attrs"
    :rules="_rules"
    v-slot="{ errors, failed }"
    :name="_name"
    ref="validation_observe"
  >
    <div>
        <div class="row">
            <div class="col-md-3 frm-l">
                <label class="lbl">{{label}}</label>
                <span v-if="required" class="text-danger">*</span>
            </div>
            <div class="col-md-9 frm-r">
                <select :name="name" v-model="_value">
                    <option value="">選択してください</option>
                    <option v-for="(item, index) in options" :key="index" :value="item.id">{{item.name}}</option>
                </select>
                <div v-if="failed" class="text-danger">{{ errors[0] }}</div>
            </div>
        </div>
    </div>
  </ValidationProvider>
</template>

<script>
const REQUIRED = "required";
export default {
  props: {
    label: { type: String, default: "" },
    name: { type: String, require: true },
    required: { type: Boolean, default: false },
    value: { require: true, default: "" },
    options: { type: Array, default: null },
    customClass: { type: String, default: "" },
    labelErr: { type: String, default: "" },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
    _required() {
      return this.required ? this.required : this._rules.includes(REQUIRED);
    },
    _name() {
      return this.labelErr ? this.labelErr : (this.label ?? this.name);
    },
    _rules() {
      let rules = this.rules ? this.rules.split("|") : [];
      if (this.required && !rules.includes(REQUIRED)) {
        rules.push(REQUIRED);
      }
      return rules.join("|");
    },
  },
};
</script>

<style>
</style>