const routes = [
	{
		path: '/term-of-service',
		name: 'term-of-service',
		component: () => import('@/modules/pc/page/static/term-service.vue'),
	},
	{
		path: '/handling-pi',
		name: 'handling-pi',
		component: () => import('@/modules/pc/page/static/handling-pi.vue'),
	},
];

export default routes;