import moment from "moment";
import Vue from "vue";
import currency from '../api/currency.json';

export default {
  methods: {
    echo(chanelName, eventName, callback) {
      if (process.env.VUE_APP_WEBSOCKET_ENABLE !== 'true') {
        return;
      }

      let Echo = require("@services/laravel-echo");
      Echo.default.channel(chanelName).listen(eventName, (response) => {
        callback({ echo: Echo.default, response });
      });
    },
    echoLeave(chanelName) {
      if (process.env.VUE_APP_WEBSOCKET_ENABLE !== 'true') {
        return;
      }

      let Echo = require("@services/laravel-echo");
      Echo.default.leaveChannel(chanelName);
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    formatDate(date, format) {
      date = date.replaceAll("-", "/");
      const defFormat = "YYYY/MM/DD";
      let newDate = new Date(date);
      newDate = moment(newDate).format(format ? format : defFormat);
      return newDate;
    },
    formatDateInstance(date, format) {
      if(!date)return;
      const defFormat = "YYYY/MM/DD";
      return moment(date).format(format ? format : defFormat);
    },
    dateInstance(date) {
      return moment(date);
    },
    formatCurrency(code, number) {
      let obj = currency[code];
      if (!obj) {
        return `${code} ${new Intl.NumberFormat().format(number)}`;
      }
      return new Intl.NumberFormat(obj.locale, obj.currency).format(number);
    },
    formatCurrencyLabel(code, number) {
      let obj = currency[code];
      if (!obj) {
        return `${code} ${new Intl.NumberFormat().format(number)}`;
      }
      return `${new Intl.NumberFormat().format(number)}${obj.label}`;
    },
    isDateTimeSameOrAfter(dt1, dt2) {
      return moment(dt1).isSameOrAfter(moment(dt2));
    },
    formatDateJa(date, format = "MMMM DD日") {
      let newDate = moment(date).locale("ja");
      return newDate.format(format) + ` (` + newDate.format("dd") + `)`;
    },
    formatDateTimeJa(date, format = "YYYY年MMMMDD日 (dd) HH:mm") {
      let newDate = moment(date).locale("ja");
      return newDate.format(format);
    },
    uniqueArray(dataArr) {
      var arr = [];
      for (var i = 0; i < dataArr.length; i++) {
        if (!arr.includes(dataArr[i])) {
          arr.push(dataArr[i]);
        }
      }
      return arr;
    },
    getMemberId() {
      return Vue.$cookies.get('C2C-MB-ID');
    },
    getNameFormURL(url) {
      if (!url) return;
      return url.substring(url.lastIndexOf('/') + 1);
    },
    isImageUrl(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },
    dateTimeAgo(date) {
      if (!date) return;
      return moment(date).locale(this.$i18n.locale).fromNow();
    }
  }
};
