import Vue from "vue";
import packageJson from "../../../package.json"

const types = {
  USER_INFO: "USER_INFO"
}

const getters = {
  appTitle: (state) => state.appTitle,
  appVersion: (state) => state.appVersion,
  getMember: (state) => state.member
}

const actions = {
 async getMMSMember({ commit }, payload) {
  let token = Vue.$cookies.get("X-MMS-Access-Token");
  if(!token){
    Vue.$cookies.remove("C2C-MB-ID");
    return;
  }
  let url = "/v1/auth/member";
  const response = await Vue.prototype.$request.
                    _requestMMS({ 
                      url, 
                      method: "GET"
                    });
  commit(types.USER_INFO, response.data);
}
}

const mutations = {
  [types.USER_INFO](state, value) {
    state.member = value
  }
}

const state = {
  appTitle: packageJson.name,
  appVersion: packageJson.version,
  member: null
}

export default {
  state,
  getters,
  actions,
  mutations
}
