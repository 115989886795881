<template>
  <div class="dropdown">
    <a
      href="javascript:void(0)"
      role="button"
      class="item"
      @click="handleMenuClick"
    >
      {{ $t("menu.notification") }}
      <span class="badge badge-pill badge-danger" v-if="notification.totalUnSeen && notification.totalUnSeen.qty">
        {{notification.totalUnSeen.qty}}
      </span>
    </a>
     <div class="popup-modal" role="dialog" v-if="isVisible">
        <div class="wrapper notification">
          <div class="modal-dialog">
            <div class="modal-content ">
              <div class="modal-header">
                <div></div>
                <h5 class="modal-title">通知</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="handleClose"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="list">
                  <ul class="p-0">
                    <template v-if="notification.data.length">
                      <li
                        role="button"
                        class="notification--border"
                        id="table"
                        v-for="(item, key) in notification.data"
                        :key="key"
                      >
                        <div class="tr"
                            @click="handleNotificationClick(item.parent_id, item.type)"
                        >
                          <small class="td">
                            {{ dateTimeAgo(item.notification_date) }}
                          </small>
                          <div class="td">
                            <span v-if="!item.is_owner">
                              {{ item.sender_name }}
                            </span>{{ item.text }}
                          </div>
                        </div>
                      </li>
                    </template>
                    <template v-else>
                      <li class="dropdown-item p-2">{{ $t("common.notification.no_notification") }}</li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    notification: Object,
  },
  data() {
    return  {
      isVisible: false
    }
  },
  methods: {
    ...mapActions({
      actionUpdateNotification: "common/updateNotification",
      fetchNotification: "common/fetchNotification",
    }),
    async handleMenuClick() {
      this.isVisible = true;
      let response = await this.actionUpdateNotification();
      if (response.data) {
        await this.fetchNotification();
      }
    },
    handleNotificationClick(id ,type)
    {
      this.isVisible = false;
      if (type === 'inquiry') {
        return this.$router.push({name: 'my-page/inquiry/:id', params: {id: id }});
      } else if (type === 'talk_room') {
        return this.$router.push({name: 'my-page/talk-room/:id', params: {id: id }});
      } else if (type === 'purchase') {
        return this.$router.push({name: 'my-page/list-lesson'});
      } else if (type === 'cancel_purchase') {
        return this.$router.push('/my-page/list-lesson?tab=cancelled');
      }else {
        return;
      }
    },
    handleClose(){
      this.isVisible = false;
    }
  }
};
</script>
<style scoped>
.dropdown-menu{
  top: 30px;
  right: -88px;
  left: unset;
}
.dropdown-menu:before{
  content: "";
  position: absolute;
  top: -20px;
  right: 90px;
  border:10px solid #343A40;
  border-color: transparent transparent #343A40 transparent;
}
.header .user .item {
    font: normal normal bold 11px/24px var(--unnamed-font-family-hiragino-sans);
    letter-spacing: 0.11px;
}
</style>