import Vue from "vue";

const types = {
    TOP_INFORMATION: "TOP_INFORMATION",
    TOP_PAGE: "TOP_PAGE",
    NOTIFICATION: "NOTIFICATION",
}

const getters = {
    getInformation: (state) => state.topInformation,
    topPage: (state) => state.topPage,
    getNotification: (state) => state.notification,
}

const actions = {
    async fetchTopPageInformation({ commit }, params) {
        let url = "/api/v1/common/top";
        const response = await Vue.prototype.$request.get({ url, params });
        commit(types.TOP_INFORMATION, response.data);
    },
    async getInquiryTypes({ commit }, { params, cb }) {
        let url = "/api/v1/common/inquiry-types";
        const response = await Vue.prototype.$request.get({ url, params });
        cb(response);
    },
    async inquiryToAdmin({ commit }, { data, cb }) {
        let url = "/api/v1/common/inquiry-to-admin";
        await Vue.prototype.$request.post({ url, data })
            .then(response => cb(response))
            .catch(error => cb(error.response));
    },
    async getTopPage({ commit }, params) {
        let url = "/api/v1/common/top-page";
        const response = await Vue.prototype.$request.get({ url, params });
        commit(types.TOP_PAGE, response.data);
    },
    async fetchNotification({ commit }, params) {
        let url = "/api/v1/common/notification";
        const response = await Vue.prototype.$request.get({ url, params });
        commit(types.NOTIFICATION, response.data);
    },
    async updateNotification({ commit }, params) {
        let url = `/api/v1/common/notification/update`;
        const response = await Vue.prototype.$request.post({ url, params });
        return response
    },
}

const mutations = {
    [types.TOP_INFORMATION](state, value) {
        state.topInformation = value
    },
    [types.TOP_PAGE](state, value) {
        state.topPage = value
    },
    [types.NOTIFICATION](state, value) {
        state.notification = value
    },
}

const state = {
    topInformation: {
        data: [],
        meta: {}
    },
    topPage: {
        information: {
            data: [],
            meta: {}
        },
        slider: {},
        popularCategory: [],
    },
    notification: {
        data: [],
        meta: {}
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
