<template>
  <div class="alert-container">
    <div
      :class="['alert', type, 'alert-dismissible']"
      role="alert"
      v-if="stateVisible && message"
    >
      {{ message }}
      <span class="close-btn" @click="close">&times;</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "Alert",
  props: {
    visible: {
      require: false,
      type: Boolean,
      default: false,
    },
    message: {
      require: true,
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "alert-success",
    },
  },
  data() {
    return {
      stateVisible: false,
    };
  },
  watch: {
    visible() {
      this.stateVisible = true;
    },
  },
  methods: {
    close() {
      this.stateVisible = false;
    },
  },
};
</script>
