<template>
  <div id="app">
    <template>
      <main v-if="isMobile()" class="sp">
        <HeaderSp :user="user" :notification="notification"/>
        <router-view />
        <FooterSp />
        <ReminderLessonModal />
      </main>
      <main v-else>
        <Header :user="user" :notification="notification"/>
        <router-view />
        <Footer />
        <ReminderLessonModal />
      </main>
      <MMS />
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "@components/pc/layout//header.vue";
import Footer from "@components/pc/layout/footer.vue";
import HeaderSp from "@components/sp/layout/header.vue";
import FooterSp from "@components/sp/layout/footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    HeaderSp,
    FooterSp,
  },
  computed: {
    ...mapGetters({
      member: "getMember",
      user: "member/getUser",
      notification: "common/getNotification",
    }),
  },
  async created() {
    this.fetchCategoryMenus();
    await this.getMMSMember();
    await this.register();
    await this.getUserInfo();
    await this.fetchNotification();
    if (this.member) {
      this.listenChannelBroadcastNotifyEvent();
    }

    window.MMSCallback.setProfileChangedListener(async (status) => {
      if(status){
        await this.register();
        await this.getUserInfo();
      }
    });
  },
  methods: {
    ...mapActions({
      getMMSMember: "getMMSMember",
      registerMember: "member/registerMember",
      getUserInfo: "member/getUserInfo",
      getMMSValueCustomFields: "user/getMMSValueCustomFields",
      fetchNotification: "common/fetchNotification",
      fetchCategoryMenus: "lessonCategory/fetchCategoryMenus",
      getInquiries: "inquiry/getInquiries",
      getTalkRooms: "talkRoom/getTalkRooms",
    }),
    async register() {
      if (!this.member) {
        return;
      }
      await this.getMMSValueCustomFields({
        cb: async (response) => {
          let data = {};
          if (response.data) {
            let obj = response.data;
            for (let [key] of Object.entries(obj)) {
              if (key == "gender" && typeof obj[key] !== "string") {
                obj[key] = obj[key][0];
              }
              data[key] = obj[key];
            }
          }
          let genderData = data["性別"];
          if (genderData == "男性") {
            genderData = "male";
          } else if (genderData == "女性") {
            genderData = "female";
          } else if (genderData == "その他") {
            genderData = "others";
          } else {
            genderData = "";
          }
          let newData = {
            nickname: this.member.name,
            date_of_birth: data["生まれた年"],
            gender: genderData,
            phone: data["電話番号"],
            first_name: data["姓"],
            last_name: data["名"],
            furigana_last_name: data["ふりがな（姓)"],
            furigana_first_name: data["ふりがな（名)"],
          };
          data = {
            ...newData,
            name: this.member.name,
            email: this.member.email,
            member_id: this.member.id,
            profile_image: this.member.photo_url,
          };
          await this.registerMember(data);
        },
      });
    },
    listenChannelBroadcastNotifyEvent() {
        let memberId = this.getMemberId();
        this.echo("notify."+memberId, ".created", ({ response }) => {
          this.fetchNotification();
          if(response.type === "inquiry" 
            && this.$route.name === "my-page/inquiry"){
            this.getInquiries();
          }else if(response.type === "talk_room" 
            && this.$route.name === "my-page/talk-room") {
            this.getTalkRooms();
          }
        });
    },
  },
};
</script>