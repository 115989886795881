const routes = [
    {
        path: '/c/:slug+',
        name: 'lesson.category.list',
        component: () => import('@/modules/pc/page/lesson-category/index.vue')
    },
    {
        path: '/rank/:slug+',
        name: 'lesson.rank.list',
        component: () => import('@/modules/pc/page/lesson-rank/index.vue')
    },
	{
		path: 'lesson-detail/:lessonId',
		name: 'lesson.detail',
		component: () => import('@/modules/pc/page/lesson-detail/index.vue'),
	}
];
export default routes;